
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { StudentGroupBreakDown } from "@/models/interface";
import InfoModal from "@/components/InfoModal.vue";
// import SchoolPerformance from "@/components/Application/SchoolAcademicPerformance/Common/SchoolPerformance.vue";
import ScrollToTopMixin from "@/mixins/scroll-to-top.mixin";
import { drawPieChart, PieChartColors } from "@/assets/js/pie-chart";
import HalfdonutProgress from "@/components/HalfdonutProgress.vue";
import SchoolMixin from "@/mixins/school.mixin";

@Component({
  components: {
    InfoModal,
    HalfdonutProgress,
    // "school-performance": SchoolPerformance,
  },
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
})
export default class BreakDownbyStudentGroup extends Mixins(ScrollToTopMixin, SchoolMixin) {
  givenTitle = "";
  givenDescription = "";
  public pielabelObj: string[] = [];
  public PieDataObj: number[] = [];

  mobileView!: boolean;
  tabletView!: boolean;
  pieChartColors = PieChartColors;

  studentGroupBreakDown: StudentGroupBreakDown = new StudentGroupBreakDown();
  constructor() {
    super();
  }

  mounted() {
    const schoolDetails = this.$store.getters["app/getschool"];
    if (schoolDetails && schoolDetails.studentGroupBreakDown) {
      this.studentGroupBreakDown = schoolDetails.studentGroupBreakDown;
    }
    this.generatepiechart();
  }
  calcCharIndex(index: any) {
    return String.fromCharCode(index + 1 + 64);
  }
  getPercentValue(value: any) {
    if (value > 50) {
      return value - 4;
    }
    return value;
  }
  generatepiechart() {
    const Pielabels = [] as any;
    const PieDatas = [] as any;

    if(Number(this.studentGroupBreakDown.populationHispanic)>0)
    {
    Pielabels.push("Hispanic");
    PieDatas.push(Number(this.studentGroupBreakDown.populationHispanic));
    }

    if(Number(this.studentGroupBreakDown.populationNativeAmerican)>0)
    {
    Pielabels.push("Native American");
    PieDatas.push(Number(this.studentGroupBreakDown.populationNativeAmerican));
    }

    if(Number(this.studentGroupBreakDown.populationPacificIslander)>0)
    {
        Pielabels.push("Pacific Islander");
        PieDatas.push(Number(this.studentGroupBreakDown.populationPacificIslander));
    }
    
    if(Number(this.studentGroupBreakDown.populationWhite)>0)
    {
      Pielabels.push("White");
      PieDatas.push(Number(this.studentGroupBreakDown.populationWhite));
    }

    if(Number(this.studentGroupBreakDown.populationAsian)>0)
    {
      Pielabels.push("Asian");
      PieDatas.push(
      Number(
        this.studentGroupBreakDown.populationAsian
          ? this.studentGroupBreakDown.populationAsian
          : 0
      )
    );
  }

  if(Number(this.studentGroupBreakDown.populationAfricanAmerican)>0)
    { 
      Pielabels.push("African American");
      PieDatas.push(
      Number(
        this.studentGroupBreakDown.populationAfricanAmerican
          ? this.studentGroupBreakDown.populationAfricanAmerican
          : 0
      )
    );
    }

    

  if(Number(this.studentGroupBreakDown.populationMultipleRaces)>0)
    {
    Pielabels.push("Multiple Races (non-Hispanic)");
    PieDatas.push(
      Number(
        this.studentGroupBreakDown.populationMultipleRaces
          ? this.studentGroupBreakDown.populationMultipleRaces
          : 0
      )
    );
    }

    this.pielabelObj = Pielabels;
    
    this.PieDataObj = PieDatas;

    //this.school.teacherWorkforce.CertTeacherSchool = "67";
    drawPieChart("pieChart", Pielabels, PieDatas);
  }

  getDataPoint(data: string) {
    if (data && data) return data.includes("%") ? `${data}` : `${data}%`;
    return "";
  }

  get yearFromEntity(){
    let result;
    if(this.schoolSystem)
    {
        result = this.overallPerformance.schoolSystemCurrentYear.letterGradeYearFormatted;
    }
      else{
        result = this.overallPerformance.schoolCurrentYear.letterGradeYearFormatted;
      }
    return result;
  }

  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  showInfo(title: string) {
    if (title) {
      switch (title) {
        case "studyEconomicallyDisadvantagedIntervention":
          title =
            this.studentGroupBreakDown
              .studyEconomicallyDisadvantagedIntervention;
          break;
        case "studyStudentswithDisabilitiesIntervention":
          title =
            this.studentGroupBreakDown
              .studyStudentswithDisabilitiesIntervention;
          break;
        case "studyEnglishLanguageLearnerIntervention":
          title =
            this.studentGroupBreakDown.studyEnglishLanguageLearnerIntervention;
          break;
        case "studyWhiteIntervention":
          title = this.studentGroupBreakDown.studyWhiteIntervention;
          break;
        case "colorAsianIntervention":
          title = this.studentGroupBreakDown.colorAsianIntervention;
          break;
        case "colorAfricanAmericanIntervention":
          title = this.studentGroupBreakDown.colorAfricanAmericanIntervention;
          break;
        case "colorHispanicIntervention":
          title = this.studentGroupBreakDown.colorHispanicIntervention;
          break;
        case "colorMultipleRacesIntervention":
          title = this.studentGroupBreakDown.colorMultipleRacesIntervention;
          break;
      }

      this.givenTitle = title;
      switch (title.toLowerCase()) {
        case "urgent intervention required":
          this.givenDescription = `Any school demonstrating at least two consecutive years of “F”-level performance with a particular group of children or any school with an out-of-school suspension rate that doubles the national average for three consecutive years shall be considered u. This label appears for a minimum of two years. If it continues to struggle to serve a particular group of students, then comprehensive intervention is required.`;
          break;
        case "urgent intervention needed":
          this.givenDescription = `Any school demonstrating “D”- or “F”-level performance with a particular group of students shall be considered Urgent Intervention Needed.`;
          break;
        default:
          (this.givenTitle = ""), (this.givenDescription = "");
          break;
      }
    }

    this.$bvModal.show("informationModal");
  }

  get ShowSectionPreparingStudentsOfColor() {
    if (
      this.getDataPoint(this.studentGroupBreakDown.colorAsian) != "" ||
      this.getDataPoint(this.studentGroupBreakDown.colorAfricanAmerican) !=
        "" ||
      this.getDataPoint(this.studentGroupBreakDown.colorHispanic) != "" ||
      this.getDataPoint(this.studentGroupBreakDown.colorMultipleRaces) != ""
    ) {
      return true;
    }
    return false;
  }

  get ShowSectionPreparingSpecificGroup() {
    if (
      this.getDataPoint(
        this.studentGroupBreakDown.studyEconomicallyDisadvantaged
      ) != "" ||
      this.getDataPoint(this.studentGroupBreakDown.studyStudentsofColor) !=
        "" ||
      this.getDataPoint(
        this.studentGroupBreakDown.studyStudentswithDisabilities
      ) != "" ||
      this.getDataPoint(
        this.studentGroupBreakDown.studyEnglishLanguageLearner
      ) != "" ||
      this.getDataPoint(this.studentGroupBreakDown.studyWhite) != ""
    ) {
      return true;
    }
    return false;
  }
}
